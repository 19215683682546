.games_section_headline { 
    color: rgb(149, 149, 149); 
    font-size: 0.75em; 
    font-weight: 400; 
    line-height: 18px;
} 

.games_section_title { 
    color: #eec13b; 
    font-size: 1em; 
    font-weight: 700;
} 
