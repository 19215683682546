.main-area {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
  /* height: 100vh; */
  /* background:#01d2d7; */
  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; */
}
.login_main-area {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.battleCard-bg {
  background-color: #f8f7f7;
  /* min-height: 100vh; */
  width: 100%;
  overflow: hidden;
}
.battleCard {
  margin: 10px 10px;
  background-color: #fff;
  border-radius: 10px;
}
.battleCard .players {
  color: #959595;
  font-size: 0.75em;
  font-weight: 700;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.battleCard .players {
  color: #959595;
  font-size: 0.75em;
  font-weight: 700;
  display: flex;
  justify-content: space-around;
}
img,
svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-column {
  flex-direction: column !important;
}
.battleCard .amount {
  font-size: 0.75em;
  font-weight: 1000;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.thin-divider-x {
  background-color: #e0e0e0;
  bottom: 0;
  height: 1px;
  left: 57px;
  right: 0;
}
.battleCard .roomCode {
  background-color: #e8eeee;
  margin: 10px 20px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-column {
  flex-direction: column !important;
}
.battleCard .roomCode span {
  font-size: 1em;
  font-weight: 700;
  color: #4b85f3;
}
.battle-input-header,
.playButton {
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif !important;
}
.playButton {
  border: none;
  border-radius: 5px;
  font-size: 0.7em;
  height: 30px;
  padding: 5px 16px;
  color: #fff;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.bg-green {
  background-color: #0db25b;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.position-static {
  position: static !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  border-radius: 0;
}
.battleCard .app-discription {
  font-size: 1em;
  font-weight: 800;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.thin-divider-x {
  background-color: #e0e0e0;
  bottom: 0;
  height: 1px;
  left: 57px;
  right: 0;
}
.battleCard .rules {
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  margin: 3px 10px;
  padding: 5px 0px;
  font-size: 1em;
  font-weight: 500;

  background-color: #f6f8fa;
  padding: 0.6rem 0rem !important;
  /* border: 1px solid #c8c8c8 !important; */
  border-radius: 10px !important;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.battleCard .rules ol {
  font-size: 0.7em;
  background-color: #f6f8fa;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  background-color: #f6f8fa !important;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  font-weight: 800;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
img,
svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.battleCard .match-status-border {
  background-color: #eee;
  padding: 10px 20px;
  font-size: 0.9em;
  font-weight: 600;
  margin: 0;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.battleCard .result-area {
  padding: 0px 20px 20px 20px;
  margin-bottom: 40px;
}
.battleCard .result-area p {
  color: #003441;
  font-size: 0.8em;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.battleCard .result-area .radios {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.battleCard .result-area label {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
}
.MuiFormControlLabel-root.Mui-disabled {
  cursor: default;
}
.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  /* margin-left: -11px; */
  /* margin-right: 16px; */
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}
.hidden {
  display: none !important;
}
.jss8 {
  color: #66bb6a;
}
.MuiRadio-root {
  color: rgba(0, 0, 0, 0.54);
}
.jss1 {
  padding: 9px;
}
.MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
.MuiButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-root {
  margin: 0;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  /* margin-left: -11px; */
  /* margin-right: 16px; */
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.battleCard .result-area button {
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px;
}
.jss4 {
  top: 0;
  left: 0;
  /* width: 100%; */
  cursor: inherit;
  /* height: 100%; */
  /* margin: 0; */
  /* opacity: 0; */
  padding: 0;
  z-index: 1;
  /* position: absolute; */
}
.jss5 {
  display: flex;
  position: relative;
}
.battleCard .app-discription {
  font-size: 0.8em;
  font-weight: 600;
}
.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-column {
  flex-direction: column !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
#post {
  font-weight: 500 !important;
}
.cancel_btn1 {
  background-color: #cad3dd;
}
.cancel_btn2 {
  background-color: green;
}
.cancel_btn3 {
  background-color: #cad3dd;
}
.cancel_btn4 {
  color: #fff;
}

.cancel_reason_btns {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: auto;
  display: flex;
  align-items: center;
  /* display: inline-block; */
}
.cancel_reason_btns input {
  display: block;
}
.cancel_reason_btns label {
  color: #fff !important;
  margin-left: 10px;
  display: block;
}
.cancel_reason_btns_bg1 {
  background-color: #0e743e !important;
  margin: 3px 10px 3px 1px !important;
  padding: 0px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.cancel_reason_btns_bg2 {
  background-color: #0e743e !important;
  margin: 3px 10px 3px 1px !important;
  padding: 0px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.cancel_reason_btns_bg3 {
  background-color: #0e743e !important;
  margin: 3px 10px 3px 1px !important;
  padding: 0px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.cancel_reason_btns_bg4 {
  background-color: #0e743e !important;
  margin: 3px 10px 3px 1px !important;
  padding: 0px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.cancel_reason_btns_bg {
  background-color: #0d3d5e !important;
  margin: 3px 10px 3px 1px !important;
  padding: 0px !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.nav_active {
  background-color: #2d9596 !important;
}

/* #reason1:hover {
  background-color: red !important;
}

#reason1.active {
  background-color: green !important;
}

#reason2:hover {
  background-color: red !important;
}

#reason2:active {
  background-color: green !important;
}
#reason3:hover {
  background-color: red !important;
}

#reason3.active {
  background-color: green !important;
}
#reason4:hover {
  background-color: red !important;
}

#reason4.active {
  background-color: green !important;
} */
.viewgame_topbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding: 10px 15px 10px 15px;
}
.rule_view {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.rule_view .nav-link {
  padding: 0%;
}

.results_btn {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 8px 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 10px;
  /* width: 100%; */
}
.results_btn_win {
  background: rgb(44 159 44);
}
.results_btn_lose {
  background: rgb(254 51 71);
}
.results_btn_cancel {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.no_lost_game_btn {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  font-size: 18px !important;
  font-weight: 600;
  margin-top: 10px !important;
  width: 100%;
}
.results_btn input {
  display: none;
}
.modal-content {
  position: absolute;
  bottom: 0;
  left: 0;
  /* max-width: 450px ; */
}
.modal-dialog {
  margin-left: 10px !important;
}
.cancel_popup_reason_popup h3 {
  color: #032995 !important;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  /* margin-top: 30px; */
  font-family: "Poppins", sans-serif !important;
}

.cancel_popup_reason_popup h5 {
  color: #252424 !important;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px;
}
.Pan_doc_upload__P0w3t .Pan_uploaded__4b6mt .Pan_size__YtYjy {
  color: #fff !important;
}
.cancel_popup_reason_popup .modal-header {
  /* background-color: red; */
  /* padding: 0;
  position: absolute;
  right: 0;
 */
  border: none;
  display: flex;
  align-items: center;
  justify-content: end;
}
.cancel_popup_reason_popup .modal-header button {
  text-decoration: none;
  background-color: transparent;
  outline: none;
  border: none;
}
.cancel_popup_reason_popup .modal-header .close {
  padding: 0;
  margin-right: 15px !important;
  margin-top: 5px !important;
  font-size: 30px !important;
}

.upload_ss_btn {
  background-color: rgb(40 172 91) !important;
  padding: 10px;
  border: 1px solid rgb(40 172 91) !important;
}
.upload_ss_btn_name {
  color: #fff !important;
  font-size: 1.3em !important;
  margin-top: 0 !important;
}
.Pan_name__e6g3x {
  color: #fff !important;
}

.checkbox_game {
  text-align: center;
}
.checkbox_game input {
  width: 20px;
}
.checkbox_game label {
  font-size: 18px;
  font-weight: 500;
  font-family: roboto;
  color: #007bff;
}

.rules .list-group-item {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
  text-transform: capitalize;
  background-color: #f6f8fa;
}
.pp {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem !important;
}

.result_buttons_group {
  display: flex !important;
  flex-direction: row !important;
}

@media screen and (max-width: 480px) {
  .results_btn {
    padding: 8px 17px;
  }
  .battleCard .roomCode {
    margin: 10px;
    padding: 10px;
  }
  .playButton {
    padding: 3px;
    font-size: 1rem !important;
  }
  .rules .list-group-item {
    font-size: 0.5rem !important;
    padding: 0.5rem !important;
  }
}

.Download_button {
 
  background-color: #000;

  color: #fff;
  padding: 8px;
  border-radius: 6px;
  text-decoration: none;
  border: none;

}
.AppImage{
  height: 45px;
  width: 65px;
  margin-right: 5px;
}
.AppClick{
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}